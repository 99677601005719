import React from "react";
import {navigate} from 'gatsby';

const Index = ({}) => {
  React.useEffect(() => {
    navigate('/team/');
  }, [])
return <div />;
};


export default Index;
